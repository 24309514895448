import React from 'react';

const RenderedPerkText = (perkTitle, perkText) => {
  const spannedPerkText = perkText && perkText.replace('<p', '<span');

  return (
    <>
      <span>{perkTitle}</span>
      {perkText && (
        <>
          : <span dangerouslySetInnerHTML={{ __html: spannedPerkText }} />
        </>
      )}
    </>
  );
};

const createList = (perks, index) => {
  const perkList = [];
  const greenCheck = perks.find(perk =>
    perk?.perkBlocks.find(
      block => block?.perkIcon?.internalName === 'Green Check Icon'
    )
  )?.perkBlocks?.[0]?.perkIcon;

  perks.map(perk => {
    const perkText = perk?.perkBlocks?.[index]?.perkText || undefined;
    const perkIcon = perk?.perkBlocks?.[index]?.perkIcon || undefined;

    if (perkIcon?.internalName === 'Green Check Icon' || perkText) {
      perkList.push({
        id: perk?.id,
        icon: greenCheck,
        heading: RenderedPerkText(perk?.perkTitle, perkText),
      });
    }
  });

  return perkList;
};

const getItems = (features, index) => {
  const accordionItems = [];

  features.map(feature => {
    accordionItems.push({
      id: feature?.id,
      heading: feature?.sectionTitle,
      subhead: feature?.subhead,
      list: createList(feature?.planPerks, index),
    });
  });

  return accordionItems;
};

export const parseFeatures = (features, index) => {
  const parsedFeatures = {
    heading: '',
    accordionItems: getItems(features, index),
    id: '',
  };

  return parsedFeatures;
};
